import { RoadmapURL, StringLabel, Themes } from "../common/constants";
import { FilterTypes, currentTheme } from "../common/types";
import { Epic, Feature, RoadMapItem } from "../model";
import { getClassName, getFormatedQuarter } from "../common/utils";
import { classNames, getColumnWidth, getModernUiStyle, getMultipleFeatureUI } from "./common.style";
import labels from "../common/FTE.string";

const InitiativeRow = ({ item, styles, columns, currentTheme }: { item: RoadMapItem, styles: any, columns: string[], currentTheme: currentTheme }) => (
  <>
    <tr>
      <td colSpan={100} className={classNames.colsFilter}>
        <div className={classNames.fName} style={styles.header}>
          <b>
            {`${labels.modernUi.initiative}${labels.modernUi.colon}`}
            <a href={`${RoadmapURL}${item?.id}`} target={StringLabel.Blank} aria-label={`Initiative link ${item.id}`}>
              {item?.id}
            </a>
            {labels.accordianWrapper.space}
            {item?.initiativeName}
          </b>
        </div>
      </td>
    </tr>
    {item.epics.map((rItem: Epic) => (
      <EpicRow key={rItem.id} rItem={rItem} columns={columns} styles={styles} currentTheme={currentTheme} />
    ))}
  </>
);

const EpicRow = ({ rItem, columns, styles, currentTheme }: { rItem: Epic, columns: string[], styles: any, currentTheme: currentTheme}) => (
  <tr className={classNames.fRow}>
    <td style={styles.roadmapItem}>
      <b>{`${labels.modernUi.epic}${labels.modernUi.colon}`}</b>
      <a href={`${RoadmapURL}${rItem.id}`} target={StringLabel.Blank} aria-label={`Epic link ${rItem.id}`}>
        {rItem?.id}
      </a>
      {labels.accordianWrapper.space}
      {rItem.roadmapItemName}
      <div>
        <b>{`${labels.accordianWrapper.solutionArea}${labels.modernUi.colon}`}</b>
        {rItem.solutionArea}
      </div>
    </td>
    {columns.map((column: string) => {
      const columnData = rItem.features.filter((f) => getFormatedQuarter(f) === column);
      return columnData.length === 1 ? (
        <FeatureCell key={columnData[0].id} feature={columnData[0]} styles={styles} />
      ) : (
        <MultipleFeaturesCell key={column} columnData={columnData} columns={columns} currentTheme={currentTheme} />
      );
    })}
  </tr>
);

const FeatureCell = ({ feature, styles }: { feature: Feature, styles: any }) => (
  <td style={styles.targetRelease}>
    <span className={classNames.statusMargin}>
      <span className={`${classNames.square}`} style={{ backgroundColor: getClassName(feature.state) }}></span>
    </span>
    <b>{`${labels.modernUi.feature}${labels.modernUi.colon}`}</b>
    <a href={`${RoadmapURL}${feature.id}`} target={StringLabel.Blank} aria-label={`Feature link ${feature.id}`}>
      {feature.id}
    </a>
    {labels.accordianWrapper.space}
    {feature.scope}
    <div>
      <b>{`${labels.accordianWrapper.workload}${labels.modernUi.colon}`}</b>
      {feature.workloadScenario.replaceAll(labels.modernUi.semiColon, labels.modernUi.semiColon + labels.accordianWrapper.space)}
    </div>
  </td>
);

const MultipleFeaturesCell = ({ columnData, columns, currentTheme }: { columnData: Feature[], columns: string[], currentTheme: currentTheme }) => (
  <td style={getColumnWidth(columns, columnData, currentTheme)}>
    <div className={classNames.formatColumn}>
      {columnData.map((f: Feature, index: number) => (
        <div key={index} className={classNames.divRow} style={getMultipleFeatureUI(f, columns, currentTheme)}>
          <span className={classNames.statusMargin}>
            <span className={`${classNames.square}`} style={{ backgroundColor: getClassName(f.state) }}></span>
          </span>
          <b>{`${labels.modernUi.feature}${labels.modernUi.colon}`}</b>
          <a href={`${RoadmapURL}${f.id}`} target={StringLabel.Blank} aria-label={`Feature link ${f.id}`}>
            {f.id}
          </a>
          {labels.accordianWrapper.space}
          {f.scope}
          <div>
            <b>{`${labels.accordianWrapper.workload}${labels.modernUi.colon}`}</b>
            {f.workloadScenario.replaceAll(labels.modernUi.semiColon, `${labels.modernUi.semiColon} `)}
          </div>
        </div>
      ))}
    </div>
  </td>
);

const ModernUI = ({
  filterItems,
  currentTheme,
  columns,
  allEpics,
}: {
  checkedFilters: FilterTypes;
  filterItems: RoadMapItem[];
  currentTheme: currentTheme;
  columns: string[];
  allEpics: Epic[];
}) => {
  const styles = getModernUiStyle(columns, currentTheme);
  const allInitiatives = new Set(allEpics.map((item) => item.initiativeId));
  const allEpicsByInitiative = Array.from(allInitiatives).map((id) => {
    const initiative = filterItems.find((initiativeItem) => initiativeItem.id === id);
    return {
      id: id,
      initiativeName: initiative?.initiativeName,
      investmentCategory: initiative?.investmentCategory,
      lastRunDateTime: initiative?.lastRunDateTime,
      epics: allEpics.filter((epic) => epic.initiativeId === id),
    } as RoadMapItem;
  });

  return (
    <div className={classNames.mainBox}>
      <h3>{labels.modernUi.fastTrackEngineering}</h3>
      <table className={classNames.tableWrapper}>
        <thead>
          <tr className={classNames.fHeader}>
            <th style={styles.th}>{labels.modernUi.roadMapItem}</th>
            {columns.map((column, index) => (
              <th key={index} style={styles.th}>
                {index === 0 ? (
                  <>
                    {column}
                    <div style={styles.triangleA}></div>
                  </>
                ) : (
                  <>
                    <div style={styles.triangleB}></div>
                    {column}
                    <div style={styles.triangleA}></div>
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allEpicsByInitiative.map((item: RoadMapItem) => (
            <InitiativeRow key={item.id} item={item} styles={styles} columns = {columns} currentTheme = {currentTheme} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ModernUI;