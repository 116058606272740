import React, { SyntheticEvent } from "react";
import {
  Accordion,
  Checkbox,
  SearchBox,
  Spinner,
  Switch,
} from "@fluentui/react-components";
import { withMsal } from "@azure/msal-react";
import { getRoadMap } from "./api";
import { AuthConfig, Epic, Feature, RoadMapItem, RoadMapList } from "./model";
import {
  DefaultFilters,
  EpicFilters,
  InvestmentCategory,
  FeatureFilters,
  TargetReleaseLabel,
  StringLabel,
  AllStatus,
  featureInfoLink,
  EngPillars,
} from "./common/constants";
import {
  currentYearQuarterDetails,
  filterArrayByStatus,
  filterEpicData,
  filterFeatureData,
  filterRoadMapItems,
  getFilterArrayByKeys,
  getFormatedDate,
  getFormatedQuarter,
  getLastTwoDigitsOfYear,
  localTime,
  replaceValuesWithEnum,
  setColumn,
  sortRoadMapItems,
} from "./common/utils";
import { HomeProps, HomeState, currentTheme, StatusFilter } from "./common/types";
import Header from "./components/Header";
import Sort from "./components/Sort";
import Pagination from "./components/Pagination";
import MainHeader from "./components/MainHeader";
import ThemeSelector from "./components/ThemeSelector";
import AccordianWrapper from "./components/AccordianWrapper";
import ModernUI from "./components/ModernUI";
import ColumnSelector from "./components/ColumnSelector";
import StatusFilterComponent from "./components/StatusFilter";
import Filters from "./components/Filters";
import labels from "./common/FTE.string";
import { classNames, getSearchboxWidth } from "./components/common.style";
import { Info16Filled   as InfoIcon } from "@fluentui/react-icons";
import StringConstant from "./common/FTE.string";

class HomeC extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps | any) {
    super(props);
    this.state = {
      roadMap: new RoadMapList(),
      allRecords: new RoadMapList(),
      pageNumbers: [],
      currentPage: Number(StringLabel.FirstPage),
      itemsPerPage: Number(StringLabel.ItemsPerPage),
      searchText: StringLabel.EmptyString,
      sortingOrder: StringLabel.SortingOrder,
      filtersList: DefaultFilters,
      checkedFilters: DefaultFilters,
      isLoading: false,
      accessToken: StringLabel.EmptyString,
      userName: StringLabel.EmptyString,
      loginName: StringLabel.EmptyString,
      wholeWordMatch: true,
      currentFilter: StringLabel.EmptyString,
      showModernUi: false,
      currentTheme: "Primary",
      columns: [],
      allColumns: [],
      statusList: AllStatus,
      statusFilterItems: new RoadMapList(),
    };
  }

  async componentDidMount(): Promise<void> {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (isAuthenticated) {
      const userName = this.props?.msalContext?.accounts[0]?.name as string;
      const loginName = this.props?.msalContext?.accounts[0]?.username as string;
      this.setState({ userName, loginName });

      const accessTokenRequest = {
        scopes: [AuthConfig.Scope],
        account: this.props?.msalContext?.instance?.getAllAccounts()[0],
      };
      await this.props?.msalContext?.instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response: any) => this.fetchData(response?.accessToken));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<HomeState>,
    snapshot?: any
  ): void {
    const { checkedFilters, currentFilter, statusList, columns } = this.state;
    const columnChanges = columns !== prevState.columns;
    if ((prevState?.checkedFilters !== checkedFilters) || (statusList !== prevState.statusList) || columnChanges) {
      this.applyFilters(columnChanges, currentFilter);
    }
  }

  private applyFilters = (columnChanges: boolean, currentFilter: string) => {
    const { checkedFilters, statusList, showModernUi, allRecords } = this.state;
    this.setState({ roadMap: allRecords });
    let statusFilterItems = [];
    const totalNoOfFilters = Object.keys(checkedFilters)
      .map((a) => (checkedFilters as { [key: string]: any })[a].length)
      .reduce((a, b) => a + b, 0);

    const checkedStatusLabels = statusList
      .filter(status => status.checked).flatMap(status => status.statusList);
    if (totalNoOfFilters === 0 && checkedStatusLabels.length === 0 && !columnChanges) {
      this.setState({
        statusFilterItems: { RoadMaps: allRecords?.RoadMaps as RoadMapItem[] },
      });
      this.resetFilters(allRecords?.RoadMaps as RoadMapItem[], currentFilter);
      return;
    } 
    let filteredData = this.filterInvestmentCategory(allRecords?.RoadMaps as RoadMapItem[], checkedFilters);

    filteredData = filteredData.filter((item) => {
      if (checkedFilters?.initiativeName?.length === 0) return true;
      return checkedFilters?.initiativeName?.includes(item?.initiativeName);
    }) ?? [];

    EpicFilters?.forEach((key) => { filteredData = filterEpicData(filteredData as RoadMapItem[], checkedFilters, key) });

    FeatureFilters?.forEach((key) => { filteredData = filterFeatureData(filteredData as RoadMapItem[], checkedFilters, key)});

    if (showModernUi) {
      filteredData = this.filterByColumns(filteredData as RoadMapItem[]);
    }

    statusFilterItems = JSON.parse(JSON.stringify(filteredData));

    if(checkedStatusLabels.length > 0)
      filteredData = filterArrayByStatus(filteredData, checkedStatusLabels);
    
    this.resetFilters(filteredData as RoadMapItem[], currentFilter);
    this.setState({
      roadMap: { RoadMaps: filteredData },
      statusFilterItems: { RoadMaps: statusFilterItems },
      currentPage: Number(StringLabel?.FirstPage),
    });
  };

  private filterByColumns = (filteredData: RoadMapItem[]) => {
    const { columns } = this.state;
    return  filteredData?.map((item) => ({
      ...item,
      epics: item?.epics
        ?.map((epic) => ({
          ...epic,
          features: epic?.features?.filter((feature) => {
            const yearQuarter = getFormatedQuarter(feature);
            return columns?.includes(yearQuarter);
          }),
        }))
        .filter((epic) => epic?.features?.length > 0),
    })).filter((item) => item?.epics?.length > 0);
  };


  private filterInvestmentCategory = (roadMap: RoadMapItem[], checkedFilters: any) => {
    if (checkedFilters?.investmentCategory?.length === 0) return roadMap;

    let filteredData = roadMap?.filter((item: RoadMapItem) => {
      if (checkedFilters?.investmentCategory?.length === 0) return true;
      return checkedFilters?.investmentCategory?.some((filter: any) =>
        item?.investmentCategory
          ?.split(";")
          .map((item: any) => item.trim())
          .includes(filter)
      );
    });
    return filteredData;
  };

  private fetchData = (authToken: string) => {
    this.setState({ isLoading: true });
    getRoadMap(authToken)
      .then((data) => {
        this.setState({ isLoading: false });
        const roadMapList: RoadMapList = new RoadMapList();
        roadMapList.RoadMaps = data;
        roadMapList.RoadMaps = roadMapList?.RoadMaps?.map(
          (item: RoadMapItem) => {
            item.investmentCategory = replaceValuesWithEnum(
              item?.investmentCategory.replace(labels.hash, labels.emptyString) || labels.emptyString,
              InvestmentCategory
            );

            item.epics = item?.epics
              ?.filter(
                (epic: Epic) =>
                  epic?.roadmapItemName !== null && epic?.roadmapItemName !== StringLabel.EmptyString  
              )
              ?.map((epic: Epic) => ({
                ...epic,
                features: epic.features?.filter(
                  (feature: Feature) => feature.state !== StringLabel.removed &&
                  (parseInt(feature.financialYear.substring(2, 4)) > getLastTwoDigitsOfYear() || currentYearQuarterDetails(feature))
                )
                .map((feature: Feature) => (EngPillars.includes(feature.engPillar) ? feature : {...feature, engPillar: StringConstant.accordianWrapper.space})),
              })).filter((epic) => epic?.features?.length > 0);

            return item;
          }
        ).filter((item) => item?.epics?.length > 0);
        const allColumns = setColumn(roadMapList as RoadMapList);
        this.setState(
          {
            roadMap: roadMapList,
            allRecords: roadMapList,
            allColumns: allColumns.allColumns,
            columns: allColumns.columns,
          },
          () => this.resetFilters(roadMapList?.RoadMaps as RoadMapItem[])
        );
        this.setPageNumbers(roadMapList);
      })
      .catch(() => this.setState({ isLoading: false }));
  };


  private setPageNumbers = (roadMapList: RoadMapList) => {
    const pageNumbers: number[] = [];

    const totalEpics = roadMapList?.RoadMaps?.reduce(
      (total, roadMap) => total + roadMap.epics.length, 0) ?? 0;

    for (let i = 1; i <= Math.ceil(totalEpics / this.state?.itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    this.setState({ pageNumbers: pageNumbers });
  };

  private onClickFilter = (ev: SyntheticEvent, data: string[], category: string) => {
    this.setState(
      (prevState) => ({
        currentFilter: category,
        checkedFilters: {
          ...prevState?.checkedFilters,
          [category]: data,
        },
      }),
      () => {
        if (Object.values(this.state?.checkedFilters).flat().length === 0) {
          this.resetFilters(this.state?.allRecords?.RoadMaps as RoadMapItem[]);
        }
      }
    );
  };

  private onClickColumn = (ev: SyntheticEvent, data: string[]) => {
    this.setState({
      columns: data,
      currentPage: Number(StringLabel.FirstPage),
    });
  };

  private clearAll = () => {
    this.setState({
      checkedFilters: DefaultFilters,
      statusFilterItems: this.state.allRecords,
      statusList: AllStatus
    });
    this.resetFilters(this.state.allRecords.RoadMaps as RoadMapItem[]);
  };

  private resetFilters = (data: RoadMapItem[], currentFilter = StringLabel.EmptyString) => {
    const filtersData = getFilterArrayByKeys(data as RoadMapItem[], Object.keys(DefaultFilters), currentFilter, this.state.filtersList);
    this.setState({
      filtersList: {
        ...filtersData,
      },
    });
  };

  render() {
    const {
      roadMap,
      currentPage,
      itemsPerPage,
      filtersList,
      checkedFilters,
      isLoading,
      sortingOrder,
      searchText,
      wholeWordMatch,
      showModernUi,
      currentTheme,
      columns,
      allColumns,
      allRecords,
      statusFilterItems
    } = this.state;
    const pageNumbers: number[] = [];
    let filterItemsLength = 0;
    let filterItems: RoadMapItem[] = [];
    let allEpics: Epic[] = [];
   
    if (roadMap && roadMap?.RoadMaps && roadMap?.RoadMaps?.length >= 0) {
      filterItems = roadMap?.RoadMaps;
      filterItems = sortRoadMapItems(filterItems, sortingOrder) || [];
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    if (roadMap && roadMap?.RoadMaps && roadMap?.RoadMaps?.length >= 0) {
      if (searchText && searchText?.length > 0) {
        filterItems = filterRoadMapItems(filterItems, searchText, wholeWordMatch);
      }

      allEpics = filterItems.flatMap((roadMap) => roadMap.epics);

      if (allEpics?.length < itemsPerPage) {
        pageNumbers?.push(1);
      } else {
        for (let i = 1; i <= Math.ceil(allEpics?.length / this.state?.itemsPerPage); i++) {
          pageNumbers?.push(i);
        }
      }
      
      filterItemsLength = allEpics.flatMap((epic) => epic.features).length;
      allEpics = allEpics.slice(indexOfFirstItem, indexOfLastItem);
    }

    return (
      <div className={classNames.container}>
        <MainHeader
          userName={this.state.userName}
          loginName={this.state.loginName}
        />
        <Header />

        {isLoading ? (
          <Spinner className={classNames.spinnerMargin} />
        ) : (
          <div className={classNames.mainBox}>
            <div className={classNames.filterWrapper}>
              <div className={classNames.search}>
                <h2>{labels.searchString}</h2>
                <SearchBox
                  style={getSearchboxWidth()}
                  placeholder={labels.search}
                  onReset={() => {
                    this.setState({ searchText: StringLabel.EmptyString });
                  }}
                  onChange={(event) => {
                    this.setState({
                      searchText: (event.currentTarget as HTMLInputElement)
                        .value,
                    });
                  }}
                />
                <div>
                  <Checkbox
                    className={classNames.checkBoxLeft}
                    label={labels.wholeWordMatch}
                    onChange={this.setWholeWordMatch}
                    checked={wholeWordMatch}
                  />
                </div>
              </div>
              <div className={classNames.filters}>
                <div className={classNames.flexItem}>
                  <h2>{labels.filterLabel}</h2>{labels.accordianWrapper.space}
                  <div style={{textAlign: "right"}}>
                      <span>
                        {labels.NextRefreshDate}
                        {localTime()}
                      </span>
                      <br />
                      <strong>{labels.LastUpdated}</strong>
                      <span>
                        {allRecords.RoadMaps && allRecords.RoadMaps[0] && getFormatedDate(
                          allRecords?.RoadMaps?.[0]?.lastRunDateTime + StringLabel.UTCDateConstantZ
                        )}
                      </span>
                    
                    
                  </div>
                </div>
                <Filters
                  onClickFilter={this.onClickFilter}
                  filtersList={filtersList}
                  checkedFilters={checkedFilters}
                  clearAll={this.clearAll}
                  isPresentationView={showModernUi}
                />
              </div>
            </div>

            <div className={classNames.flexItem}>
              <div>
                <strong>
                  Showing{labels.accordianWrapper.space}
                  <span className={classNames.filterColor}>
                    {filterItemsLength}
                  </span>{labels.accordianWrapper.space}
                  updates:
                </strong>
              </div>

              <div className={classNames.flexItem}>
                {showModernUi && (
                  <ColumnSelector
                    targetReleaseFilters={[...new Set([...allColumns])]}
                    onClickColumn={this.onClickColumn}
                    selectedColumns={columns}
                  />
                )}
                &nbsp;
                {showModernUi && (
                  <ThemeSelector
                    currentTheme={currentTheme}
                    onSelectTheme={this.onSelectTheme}
                  />
                )}
                <Switch
                  label={showModernUi ? labels.switchDetailView: labels.switchPresentationView}
                  onChange={this.setModernUi}
                  checked={showModernUi}
                />
              </div>
            </div>
            <div>
                <h3>
                  <span>{labels.statusFilterTitle} </span>
                  <span className={classNames.infoIconLink}>
                    <a href={featureInfoLink} target={StringLabel.Blank} aria-label={StringConstant.featureInfo} >
                      <InfoIcon style={{ color: '#0278d4'}} />
                    </a>
                  </span>
                </h3>
                <StatusFilterComponent
                  allStatus={this.state.statusList}
                  allFeatures={statusFilterItems?.RoadMaps?.flatMap((roadMap) => roadMap.epics).flatMap((epic) => epic.features) || []}
                  onStatusChange={this.onStatusChange}
                />
            </div>
          </div>
        )}

        {allEpics.length > 0 ? (
          showModernUi ? (
            <ModernUI
              checkedFilters={checkedFilters}
              filterItems={filterItems}
              currentTheme={currentTheme}
              columns={columns.sort()}
              allEpics={allEpics}
            />
          ) : (
            <div className={classNames.main}>
              <div className={classNames.mainBox}>
                <div className={classNames.sortWrapper}>
                  <h2>{labels.initiativeNameHeading}</h2>
                  <Sort sortingOrder={sortingOrder} onSort={this.onSort} />
                </div>
                <div className={classNames.accordians}>
                  <Accordion collapsible>
                    {allEpics.map((epic: Epic, itemIndex: number) => {
                      return (
                        <AccordianWrapper
                          key={`${itemIndex}}`}
                          value={`${itemIndex}}`}
                          item={
                            allRecords?.RoadMaps?.find(
                              (item: RoadMapItem) =>
                                item.id === epic.initiativeId
                            ) as RoadMapItem
                          }
                          epic={epic}
                          checkedFilters={checkedFilters}
                        />
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          )
        ) : (
          !isLoading && <div className={classNames.errMsg}>{labels.noDataFound}</div>
        )}

        {filterItemsLength > 0 && (
          <Pagination
            currentPage={this.state.currentPage}
            handlePrevPageClick={this.handlePrevPageClick}
            pageNumbers={pageNumbers}
            handlePageClick={this.handlePageClick}
            handleNextPageClick={this.handleNextPageClick}
          />
        )}
      </div>
    );
  }
  
  private onStatusChange = (ev: SyntheticEvent, selectedStatus: StatusFilter) => {
    this.setState(prevState => ({
      statusList: prevState.statusList.map(cb =>
        cb.status === selectedStatus.status ? { ...cb, checked: !selectedStatus.checked } : cb
      ),
    }));
  }

  private onSelectTheme = (theme: currentTheme) => {
    this.setState({
      currentTheme: theme,
    });
  };

  private setModernUi = () => {
    this.resetFilters(this.state.allRecords.RoadMaps as RoadMapItem[]);
    this.setState((prevState) => ({
      showModernUi: !prevState.showModernUi,
      checkedFilters: {
        ...prevState.checkedFilters,
        [TargetReleaseLabel]: [],
      },
    }));
  };

  private setWholeWordMatch = () => {
    this.setState((prevState) => ({
      wholeWordMatch: !prevState.wholeWordMatch,
    }));
  };

  private onSort = (value: string) => {
    this.setState({ sortingOrder: value as string });
  };

  private handlePageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLAnchorElement;
    const id = target.id;
    this.setState({ currentPage: parseInt(id) });
  };

  private handlePrevPageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const currentPage = this.state.currentPage;
    if (this.state.currentPage === 1) {
      return;
    }
    const id = currentPage - 1;
    this.setState({ currentPage: id });
  };

  private handleNextPageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const currentPage = this.state?.currentPage;
    if (this.state?.currentPage === this.state?.pageNumbers?.length) {
      return;
    }
    const id = currentPage + 1;
    this.setState({ currentPage: id });
  };
}

const Home = withMsal(HomeC);
export default Home;
