import React, { useCallback, useMemo } from "react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-components";
import { Themes } from "../common/constants";
import { currentTheme } from "../common/types";
import labels from "../common/FTE.string";
import { classNames, getThemeDropDownStyle, getThemeSelectorStyle } from "./common.style";

const ThemeSelector = ({ currentTheme, onSelectTheme }: { currentTheme: currentTheme, onSelectTheme: any }) => {
    const handleOptionSelect = useCallback(
        (event: SelectionEvents, data: OptionOnSelectData) => {
            onSelectTheme(data.optionValue);
        },
        [onSelectTheme]
    );

    const themeOptions = useMemo(() => (
        Object.keys(Themes).map((theme, index) => (
            <Option key={theme} text={theme} value={theme} style={getThemeSelectorStyle().optionStyle} aria-label={`theme-${index}`}>
                {theme}
                <div style={getThemeSelectorStyle().optionDataStyle}>
                    <div style={getThemeDropDownStyle(theme).MainHeader}>&nbsp;</div>
                    <div style={getThemeDropDownStyle(theme).header}>&nbsp;</div>
                    <div style={getThemeDropDownStyle(theme).roadmapItem}>&nbsp;</div>
                    <div style={getThemeDropDownStyle(theme).targetRelease}>&nbsp;</div>
                </div>
            </Option>
        ))
    ), [Themes]);

    return (
        <Dropdown
            id="theme-selector-id"
            aria-label="theme-selector"
            onOptionSelect={handleOptionSelect}
            placeholder={labels.chooseTheme}
            selectedOptions={[currentTheme]}
            defaultSelectedOptions={[currentTheme]}
            className={classNames.sortWidth}     
        >
            {themeOptions}
        </Dropdown>
    );
};

export default ThemeSelector;