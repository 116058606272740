import { AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-components";
import { AccordianProps } from "../common/types";
import { getClassName, transformFeatureArray } from "../common/utils";
import { RoadmapURL, StringLabel } from "../common/constants";
import StringConstant from "../common/FTE.string";
import { classNames } from "./common.style";
import FeatureDetails from "./FeatureDetails";
import EpicDetails from "./EpicDetails";
import { Feature } from "../model";

const AccordianWrapper = ({
  item,
  value,
  epic,
}: AccordianProps) => {
  const transformFeatures = transformFeatureArray(epic.features);

  return (
    <AccordionItem value={value} className={classNames.accordian}>
      <AccordionHeader>
        <div className={classNames.accordianHeaderContainer}>
          <span className={classNames.accordianHeader}>
            {item?.initiativeName} -{StringConstant.accordianWrapper.space}
            <a href={`${RoadmapURL}${epic.id}`} target={StringLabel.Blank} aria-label={`Epic link ${epic.id}`}>
              {epic?.id}
            </a>
            {StringConstant.accordianWrapper.space}
            {epic?.roadmapItemName}
          </span>
        </div>
      </AccordionHeader>
      <AccordionPanel>
        <div className={classNames.accordianBody}>
          <div className={classNames.accordianSection}>
            {transformFeatures.map((feature: Feature, index: number) => (
              <FeatureDetails key={index} feature={feature} />
            ))}
          </div>
          <div className={classNames.accordionDivision} />
          <EpicDetails epic={epic} item={item} />
        </div>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordianWrapper;