
export const timeZoneAbbreviations = {
    "Asia/Calcutta": "IST",
    "America/Los_Angeles": "PST",
    "America/New_York": "EST",
    "Europe/London": "GMT",
    "Europe/Paris": "CET",
    "Asia/Tokyo": "JST",
    "Australia/Sydney": "AEST",
    "America/Chicago": "CST",
    "America/Denver": "MST",
    "America/Phoenix": "MST",
    "America/Anchorage": "AKST",
    "Pacific/Honolulu": "HST",
    "Africa/Cairo": "EET",
    "Africa/Johannesburg": "SAST",
    "Asia/Dubai": "GST",
    "Asia/Hong_Kong": "HKT",
    "Asia/Singapore": "SGT",
    "Asia/Seoul": "KST",
    "Asia/Shanghai": "CST",
    "Europe/Berlin": "CET",
    "Europe/Moscow": "MSK",
    "Europe/Rome": "CET",
    "Europe/Madrid": "CET",
    "Europe/Amsterdam": "CET",
    "Europe/Zurich": "CET",
    "Europe/Brussels": "CET",
    "Europe/Stockholm": "CET",
    "Europe/Vienna": "CET",
    "Europe/Warsaw": "CET",
    "Europe/Budapest": "CET",
    "Europe/Prague": "CET",
    "Europe/Athens": "EET",
    "Europe/Istanbul": "TRT",
    "Europe/Kiev": "EET",
    "Europe/Helsinki": "EET",
    "Europe/Bucharest": "EET",
    "Europe/Sofia": "EET",
    "Europe/Riga": "EET",
    "Europe/Vilnius": "EET",
    "Europe/Tallinn": "EET",
    "Europe/Minsk": "MSK",
    "Europe/Belgrade": "CET",
    "Europe/Oslo": "CET",
    "Europe/Copenhagen": "CET",
    "Europe/Lisbon": "WET",
    "Europe/Dublin": "GMT",
    "Europe/Reykjavik": "GMT",
    "Europe/Luxembourg": "CET",
    "Europe/Monaco": "CET",
    "Europe/San_Marino": "CET",
    "Europe/Vatican": "CET",
    "Europe/Andorra": "CET",
    "Europe/Malta": "CET",
    "Europe/Podgorica": "CET",
    "Europe/Sarajevo": "CET",
    "Europe/Skopje": "CET",
    "Europe/Tirane": "CET",
    "Europe/Zagreb": "CET",
    "Europe/Ljubljana": "CET",
    "Europe/Bratislava": "CET",
    "Europe/Chisinau": "EET",
    "Europe/Kaliningrad": "EET",
    "Europe/Samara": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
    "Europe/Astrakhan": "SAMT",
    "Europe/Saratov": "SAMT",
    "Europe/Ulyanovsk": "SAMT",
    "Europe/Volgograd": "MSK",
    "Europe/Kirov": "MSK",
};